// ----- Modules ----- //
import React, { useEffect, useMemo, useState } from 'react';
// ----- Components ----- //
import CardComponent from "../components/CardComponent";
// ----- MUI ----- //
import { Box, CircularProgress, Typography } from '@mui/material';
// ----- Utils ----- //
import { CustomDataGrid } from '../utils/Theme';
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { ValueFormatter } from "../utils/ValueFormatter";

interface FetchLog {
  fetchType: string;
  lastRun: string;
  status: string;
  lastSuccess: string | null;
}

const FetchLogs: React.FC = () => {
  const axiosInstance = useConfiguredAxios();

  const [logs, setLogs] = useState<FetchLog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchLogs = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get('/api/fetch-logs');
      const formattedLogs = Object.entries(response.data).map(([fetchType, log]: [string, any]) => ({
        fetchType,
        lastRun: new Date(log.lastRun).toLocaleString(),
        lastSuccess: log.lastSuccess ? new Date(log.lastSuccess).toLocaleString() : 'N/A',
        status: log.status,
      }));
      setLogs(formattedLogs);
    } catch (err) {
      setError('Failed to fetch logs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs().then();
  }, []);

  const columns = useMemo(
    () => [
      {field: 'fetchType', headerName: 'Fetch Type', flex: 1},
      {
        field: 'lastRun',
        headerName: 'Last Run',
        flex: 1,
        valueFormatter: (value: any) => ValueFormatter.date(value, true)
      },
      {
        field: 'lastSuccess', headerName: 'Last Success', flex: 1,
        renderCell: (params: any) => params.row.status !== 'success' ? ValueFormatter.date(params.value, true) : '',
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: any) => (
          <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>

            <Typography color={params.value === 'success' ? 'primary' : 'error'}
                        sx={{fontWeight: 600}}>
              {params.value}
            </Typography>
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <CardComponent
      title="Fetch Logs"
      color="#8fa832"
      subtitle="Recent fetch logs and their statuses"
      defaultHeight="calc(100vh - 200px)"
      hideMaximize={true}
    >
      {loading ? (
        <CircularProgress/>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <CustomDataGrid
          getRowId={(row) => row.fetchType}
          columns={columns}
          rows={logs}
          density="compact"
          loading={loading}
        />
      )}
    </CardComponent>
  );
};

export default FetchLogs;

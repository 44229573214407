// ----- Modules ----- //
import React, { useEffect, useState } from "react";
// ----- MUI ----- //
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
// ----- Utils ----- //
import { useConfiguredAxios } from "../../../utils/AxiosInstance";
// ----- Types ----- //
import { Order } from "../types";

interface EditOrderModalProps {
  orderId: string;
  open: boolean;
  onClose: () => void;
  order: Order | null;
  onEdit: (order: Order) => void;
}

const EditOrderModal: React.FC<EditOrderModalProps> = ({
                                                         orderId,
                                                         open,
                                                         onClose,
                                                         order,
                                                         onEdit,
                                                       }) => {
  const [cost, setCost] = useState<string | null>(order?.cost || null);
  const [vividseatsId, setVividseatsId] = useState<string | null>(order?.vividseats_id || null);
  const [purchaseId, setPurchaseId] = useState<string | null>(order?.purchase_id || null);
  const [fetchingVividseatsId, setFetchingVividseatsId] = useState<boolean>(false);

  const axiosInstance = useConfiguredAxios();

  useEffect(() => {
    setPurchaseId(order?.purchase_id || null);
    setCost(order?.cost || null);
    setVividseatsId(order?.vividseats_id || "");
  }, [order]);

  const handleEdit = () => {
    if (order) {
      onEdit({
        ...order,
        cost: cost || null,
        vividseats_id: vividseatsId || null,
        purchase_id: purchaseId || null,
      });
      onClose();
    }
  };

  const fetchViaVividSeatsId = async () => {
    setFetchingVividseatsId(true);
    try {
      const vividSeatsId = await axiosInstance
        .put(`/api/orders/mpv/match/${orderId}`)
        .then((response) => response.data);
      setVividseatsId(vividSeatsId);
    } catch (error) {
      console.error("Error fetching Vivid Seats ID:", error);
    } finally {
      setFetchingVividseatsId(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Order</DialogTitle>
      <DialogContent>
        <DialogContentText>Update the order details.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Purchase ID"
          type="text"
          fullWidth
          value={purchaseId}
          onChange={(e) => setPurchaseId(e.target.value || null)}
          size="small"
        />
        <TextField
          margin="dense"
          label="Cost"
          type="number"
          fullWidth
          value={cost}
          onChange={(e) => setCost(e.target.value || null)}
          size="small"
        />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <TextField
            margin="dense"
            label="Vivid Seats ID"
            type="text"
            fullWidth
            value={vividseatsId}
            onChange={(e) => setVividseatsId(e.target.value || null)}
            size="small"
          />
          {!vividseatsId && (
            <Box sx={{m: 1, position: "relative"}}>
              <Button
                color="primary"
                onClick={fetchViaVividSeatsId}
                variant="contained"
                disabled={fetchingVividseatsId}
              >
                Fetch
              </Button>
              {fetchingVividseatsId && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "primary",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleEdit} color="success">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConfirmDeleteModalProps {
  open: boolean;
  onClose: () => void;
  orderId: string | null;
  onDelete: (orderId: string, market: string) => void;
  market: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 orderId,
                                                                 onDelete,
                                                                 market,
                                                               }) => {
  const handleDelete = () => {
    if (orderId) {
      onDelete(orderId, market);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to soft delete this order?
        </DialogContentText>
        <Typography variant="body2" color="textSecondary">
          The order will be marked as deleted and will not be visible in the list.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error">
          Soft delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConfirmPOModalProps {
  open: boolean;
  onClose: () => void;
  orderId: string | null;
  onPO: (orderId: string) => void;
}

const ConfirmPOModal: React.FC<ConfirmPOModalProps> = ({
                                                         open,
                                                         onClose,
                                                         orderId,
                                                         onPO,
                                                       }) => {
  const handlePO = () => {
    if (orderId) {
      onPO(orderId);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm PO</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to PO this order?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handlePO} color="success">
          Yesssir!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditOrderModal, ConfirmDeleteModal, ConfirmPOModal };

import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useCallback, useState } from "react";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { GridRowId, GridValidRowModel } from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import axios from "axios";

interface BulkActionsProps {
  selectedOrdersFct: () => Map<GridRowId, GridValidRowModel>;
  setCurrentOrderROD: (id: string) => void;
  setCurrentOrderPO: (id: string) => void;
  fetchOrders: () => void;
}

// Reusable CircularProgress Component
const ProgressOverlay = ({active}: { active: boolean }) => (
  active ? (
    <CircularProgress
      size={24}
      sx={{
        color: "primary",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: "-12px",
        marginLeft: "-12px",
      }}
    />
  ) : null
);

const BulkActions = (props: BulkActionsProps) => {
  const {selectedOrdersFct, setCurrentOrderROD, setCurrentOrderPO, fetchOrders} = props;

  const [fetchingRawData, setFetchingRawData] = useState<boolean>(false);
  const [poingOrder, setPoingOrder] = useState<boolean>(false);

  const getSelectedOrders = useCallback((): GridRowId[] => {
    const selectedOrders = selectedOrdersFct();

    if (selectedOrders.size === 0) {
      enqueueSnackbar("No orders selected.", {
        variant: "warning",
        autoHideDuration: 5000,
        preventDuplicate: false,
      });
      return [];
    }

    enqueueSnackbar(`${selectedOrders.size} orders selected.`, {
      variant: "info",
      autoHideDuration: 5000,
      preventDuplicate: false,
    });

    return Array.from(selectedOrders.keys());
  }, [selectedOrdersFct]);

  const handleRawOrderDataClick = useCallback(async () => {
    setFetchingRawData(true);

    const selectedOrders = getSelectedOrders();
    if (selectedOrders.length === 0) {
      setFetchingRawData(false);
      return;
    }

    for (const orderId of selectedOrders) {
      await fetchRawOrderData(orderId);
    }

    setCurrentOrderROD('');
    setFetchingRawData(false);
    fetchOrders();
  }, [getSelectedOrders, setCurrentOrderROD, fetchOrders]);

  const fetchRawOrderData = useCallback(async (orderId: GridRowId) => {
    setCurrentOrderROD(orderId as string);
    try {
      await axios.post(`https://mcdavidalb.plessinc.com/purchases/parsed/orderdata/${orderId}`);
      enqueueSnackbar("Raw order data fetched successfully.", {
        variant: "success",
        autoHideDuration: 5000,
        preventDuplicate: false,
      });
    } catch (error) {
      handleError("Unable to fetch the data. Please try again later.", error);
    }
  }, [setCurrentOrderROD]);

  const handlePOClick = useCallback(async () => {
    setPoingOrder(true);

    const selectedOrders = getSelectedOrders();
    if (selectedOrders.length === 0) {
      setPoingOrder(false);
      return;
    }

    for (const orderId of selectedOrders) {
      await poOrder(orderId);
    }

    setCurrentOrderPO('');
    setPoingOrder(false);
    fetchOrders();
  }, [getSelectedOrders, setCurrentOrderPO]);

  const poOrder = useCallback(async (orderId: GridRowId) => {
    setCurrentOrderPO(orderId as string);
    try {
      await axios.post(`https://mcdavidalb.plessinc.com/purchases/parsed/po/${orderId}`);
      enqueueSnackbar("PO successfully created.", {
        variant: "success",
        autoHideDuration: 5000,
        preventDuplicate: false,
      });
    } catch (error) {
      handleError("Unable to create PO. Please try again later.", error);
    }
  }, [setCurrentOrderPO]);

  const handleError = (message: string, error: any) => {
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: 10000,
      preventDuplicate: false,
    });
    console.error(message, error);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 0.25,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Tooltip title={"Fetch selected orders' raw data"} arrow placement="top" disableInteractive>
        <Box sx={{position: "relative"}}>
          <IconButton
            size="small"
            color="secondary"
            onClick={handleRawOrderDataClick}
            disabled={fetchingRawData || poingOrder}
          >
            <CloudSyncIcon/>
          </IconButton>
          <ProgressOverlay active={fetchingRawData}/>
        </Box>
      </Tooltip>
      <Tooltip title={"PO selected orders"} arrow placement="top" disableInteractive>
        <Box sx={{position: "relative"}}>
          <IconButton
            size="small"
            color="warning"
            onClick={handlePOClick}
            disabled={fetchingRawData || poingOrder}
          >
            <SendIcon/>
          </IconButton>
          <ProgressOverlay active={poingOrder}/>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default BulkActions;

import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { ValueFormatter } from "../../../utils/ValueFormatter";
import React from "react";

export const getColumns = (handleViewEmail: (s3_id: string) => void) => [
  {
    field: "view",
    headerName: "",
    width: 60,
    sortable: false,
    renderCell: (params: any) => (
      <IconButton
        onClick={() => handleViewEmail(params.row.s3_id)}
        color="primary"
        aria-label="view"
      >
        <EmailIcon/>
      </IconButton>
    ),
  },
  {field: "title", headerName: "Title", width: 500},
  {field: "sender", headerName: "Sender", width: 450},
  {field: "recipient", headerName: "Recipient", width: 300},
  {
    field: "updated_at",
    headerName: "Updated At",
    width: 150,
    valueGetter: (value: any) => ValueFormatter.date(value, true),
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 150,
    valueGetter: (value: any) => ValueFormatter.date(value, true),
  },
];

import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import React from "react";

const Actions = (props: any) => {
  const {onFetchRawData, fetchingRawData, order} = props;
  const [tries, setTries] = React.useState(0);

  return (
    <>
      <Tooltip title={"Fetch raw order data"} arrow placement="top" disableInteractive>
        <Box sx={{position: "relative"}}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onFetchRawData(order);
              setTries(tries + 1);
            }}
            disabled={fetchingRawData || !!order.raw_order_data}
          >
            <CloudSyncIcon/>
          </IconButton>
          {fetchingRawData === order.id && (
            <CircularProgress
              size={24}
              sx={{
                color: "primary",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Tooltip>
    </>
  );
};

export default Actions;

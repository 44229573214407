import moment from "moment/moment";

export const shortcutsItems: { label: string; getValue: () => [moment.Moment, moment.Moment] }[] = [
  {
    label: 'This Month',
    getValue: () => {
      const today = moment();
      return [today.clone().startOf('month'), today.clone().endOf('day')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(7, 'days').startOf('day'), today.clone().endOf('day')];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(30, 'days').startOf('day'), today.clone().endOf('day')];
    },
  },
  {
    label: 'This Week',
    getValue: () => {
      const today = moment();
      return [today.clone().startOf('week'), today.clone().endOf('day')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = moment();
      const startOfLastWeek = today.clone().subtract(1, 'week').startOf('week');
      const endOfLastWeek = today.clone().subtract(1, 'week').endOf('week').isAfter(today)
        ? today.clone().endOf('day')
        : today.clone().subtract(1, 'week').endOf('week');
      return [startOfLastWeek, endOfLastWeek];
    },
  },
  {
    label: moment().subtract(1, 'month').format('MMMM'),
    getValue: () => {
      const lastMonth = moment().subtract(1, 'month');
      return [lastMonth.clone().startOf('month'), lastMonth.clone().endOf('month')];
    },
  },
  {
    label: moment().subtract(2, 'month').format('MMMM'),
    getValue: () => {
      const twoMonthsAgo = moment().subtract(2, 'month');
      return [twoMonthsAgo.clone().startOf('month'), twoMonthsAgo.clone().endOf('month')];
    },
  },
  {
    label: moment().subtract(3, 'month').format('MMMM'),
    getValue: () => {
      const threeMonthsAgo = moment().subtract(3, 'month');
      return [threeMonthsAgo.clone().startOf('month'), threeMonthsAgo.clone().endOf('month')];
    },
  },
];

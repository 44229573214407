// ----- Modules ----- //
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { customMarketTransformations, headerMappings, StandardObject } from './standardObject';
import { enqueueSnackbar } from "notistack";
import { adjustmentHeaderMappings } from "./standardAdjustmentObject";

// Utility function to remove empty rows
const cleanDataRows = (data: any[]): any[] => {
  return data.filter(row => {
    const firstColumnKey = Object.keys(row)[0];
    return row[firstColumnKey];
  });
};

// Utility function to parse a file using a given parser
const parseFile = (
  file: File,
  parser: (file: File, options: any) => any,
  cleanData: (data: any[]) => any[],
  setStatus?: React.Dispatch<React.SetStateAction<string>>,
  startLine: number = 0 // Start from this line; this line will be used as header
): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    parser(file, {
      header: false, // Do not use automatic header parsing
      complete: (results: any) => {
        // Remove all rows before the startLine index
        const rawData = results.data.slice(startLine);

        if (rawData.length === 0) {
          enqueueSnackbar(`No data found starting from line ${startLine}`, {variant: 'warning'});
          resolve([]);
          return;
        }

        // The first row is the header
        const headers = rawData[0];
        // Remaining rows are the data
        const dataRows = rawData.slice(1);

        // Convert data rows into objects with headers as keys
        const parsedData = cleanData(
          dataRows.map((row: any) => {
            const dataObj: any = {};
            headers.forEach((header: string, index: number) => {
              dataObj[header] = row[index];
            });
            return dataObj;
          })
        );

        setStatus && setStatus(`${parsedData.length} lines processed`);
        enqueueSnackbar(`${parsedData.length} lines successfully parsed`, {variant: 'success'});
        resolve(parsedData);
      },
      error: (error: Error) => {
        enqueueSnackbar(`Error parsing file: ${error.message}`, {variant: 'error', autoHideDuration: null});
        reject(error);
      },
    });
  });
};

// Updated CSV parser function
export const parseCsv = (
  file: File,
  setStatus?: React.Dispatch<React.SetStateAction<string>>,
  startLine: number = 0 // Start from this line; this line will be used as header
): Promise<any[]> => {
  return parseFile(file, Papa.parse, cleanDataRows, setStatus, startLine);
};

// Updated XLSX parser function
export const parseXlsx = (
  file: File,
  setStatus?: React.Dispatch<React.SetStateAction<string>>,
  startLine: number = 0 // Start from this line; this line will be used as header
): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, {type: 'array', cellDates: true});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // Get all rows in the worksheet
        const allRows = XLSX.utils.sheet_to_json(worksheet, {header: 1});

        // Remove all rows before the startLine index
        const rawData = allRows.slice(startLine);

        if (rawData.length === 0) {
          enqueueSnackbar(`No data found starting from line ${startLine}`, {variant: 'warning'});
          resolve([]);
          return;
        }

        // The first row is the header
        const headers = rawData[0] as string[];
        // Remaining rows are the data
        const dataRows = rawData.slice(1);

        // Convert data rows into objects with headers as keys
        const jsonData = cleanDataRows(
          dataRows.map((row: any) => {
            const dataObj: any = {};
            headers.forEach((header: string, index: number) => {
              dataObj[header] = row[index];
            });
            return dataObj;
          })
        );

        setStatus && setStatus(`Processed ${jsonData.length} lines`);
        enqueueSnackbar(`${jsonData.length} lines successfully parsed`, {variant: 'success'});
        resolve(jsonData);
      } catch (error: any) {
        enqueueSnackbar(`Error parsing XLSX file: ${error.message}`, {variant: 'error', autoHideDuration: null});
        reject(error);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

// Function to map data to standard object
export const mapToStandardObject = (
  data: any[],
  marketId: number,
  paymentDate: string,
  paymentId: string,
  account: 'LONG' | 'DS',
  isAdjustments: boolean = false
): StandardObject[] | null => {
  const mapping = headerMappings[marketId];
  const adjustmentMapping = adjustmentHeaderMappings[marketId];

  if (!paymentId || !paymentDate) {
    enqueueSnackbar('Payment ID and Payment Date are required for this market', {
      variant: 'error',
      autoHideDuration: null,
    });
    return null;
  }

  try {
    const result = data.map(row => {
      const standardObject: StandardObject = {
        id: null,
        order_id: null,
        payment_id: paymentId,
        payment_date: paymentDate,
        market_id: marketId,
        amount: null,
        account: account,
        type: null,
        description: null,
        updated_at: null,
        created_at: null,
      };

      if (customMarketTransformations[marketId] && !isAdjustments) {
        return customMarketTransformations[marketId](row, account, paymentId, paymentDate);
      }

      for (const [key, value] of Object.entries(row)) {
        const standardKey = isAdjustments ? adjustmentMapping[key] : mapping[key];
        if (standardKey) {
          if (standardKey === 'amount') {
            standardObject[standardKey] = (parseAmount(value) || 0) * (isAdjustments ? -1 : 1);
          } else if (standardKey === 'created_at' || standardKey === 'updated_at') {
            // @ts-ignore
            standardObject[standardKey] = adjustToLocalTimezone(new Date(value));
          } else if (standardKey === 'description' && value === 'RT Fee' && isAdjustments) {
            standardObject['order_id'] = '99957999';
            standardObject[standardKey] = value;
          } else {
            // @ts-ignore
            standardObject[standardKey] = value;
          }
        }
      }
      return standardObject;
    });

    enqueueSnackbar('Data successfully mapped to standard object', {variant: 'success'});
    return result;

  } catch (error: any) {
    enqueueSnackbar(`Error processing row: ${error.message}`, {
      variant: 'error',
      autoHideDuration: null,
    });
    return null;
  }
};

// Function to create payment object
export const createPaymentObject = (
  paymentLines: StandardObject[],
  paymentId: string,
  marketId: number,
  paymentDate: string
) => {
  return {
    payment_id: paymentId,
    market_id: marketId,
    payment_date: paymentDate,
    created_at: new Date().toISOString(),
    payment_lines: paymentLines,
  };
};

// Function to format the payment data after parsing
export const formatPaymentData = (
  parsedData: any[],
  marketId: number,
  paymentDate: string,
  paymentId: string,
  account: 'LONG' | 'DS',
  isAdjustments: boolean = false
): { payment: any } => {
  const mappedData = mapToStandardObject(parsedData, marketId, paymentDate, paymentId, account, isAdjustments);
  if (!mappedData) return {payment: null};

  const payment = createPaymentObject(mappedData, paymentId, marketId, paymentDate);

  // @ts-ignore
  if (marketId === 4443058) payment.currency = mappedData[0]?.currency;

  return {payment};
};

// Utility function to parse amount
export const parseAmount = (value: any): number | null => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(/[^0-9.-]+/g, ''));
  } else if (typeof value === 'number') {
    return value;
  }
  enqueueSnackbar('Invalid amount format detected', {variant: 'warning'});
  return null;
};

// Function to adjust date to local timezone
const adjustToLocalTimezone = (date: Date): string => {
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate.toISOString().slice(0, 19); // Remove milliseconds and 'Z'
};

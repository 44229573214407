// ----- Modules ----- //
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useConfiguredAxios } from "../../../utils/AxiosInstance";
import { enqueueSnackbar } from "notistack";

// ----- MUI ----- //
import ReceiptIcon from '@mui/icons-material/Receipt';
import { GridDataSource, GridFilterModel, gridFilterModelSelector, useGridApiRef } from "@mui/x-data-grid-premium";

// ----- Providers ----- //
import { InvoicesContext, InvoicesSearchParams } from "../../../contexts/InvoicesProvider";

// ----- Components ----- //
import CardComponent from "../../../components/CardComponent";
import { CustomDataGrid } from "../../../utils/Theme";
import { columns, HeaderComponent, PaymentsDetailModal, VerifyConfirmModal } from "./Common";

// ----- Types ----- //
import { PaymentLineType } from "../../../utils/Types";

const SkyboxInvoices = () => {
  const axiosInstance = useConfiguredAxios();
  const apiRef = useGridApiRef();
  const {getInvoices, loading} = useContext(InvoicesContext);

  // ----- States ----- //
  const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel | null>(null);
  const [date, setDate] = useState(moment());
  const [onlyUnverified, setOnlyUnverified] = useState<boolean>(false);
  const [onlyWithPayments, setOnlyWithPayments] = useState<boolean>(false);
  const [selectedPayments, setSelectedPayments] = useState<PaymentLineType[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [verifyConfirmOpen, setVerifyConfirmOpen] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyError, setVerifyError] = useState<string | null>(null);

  const [selectedId, setSelectedId] = useState<string | null>(null);

  // ----- Effects ----- //
  useEffect(() => {
    const handleFilterChange = () => {
      setGridFilterModel(gridFilterModelSelector(apiRef.current.state as any));
    };

    apiRef.current.subscribeEvent('filterModelChange', handleFilterChange);
    setGridFilterModel(gridFilterModelSelector(apiRef.current.state as any));
  }, [apiRef]);

  // ----- Handlers ----- //
  const handleOpenModal = (payments: PaymentLineType[]) => {
    setSelectedPayments(payments);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenVerifyConfirm = (id: string) => {
    setSelectedId(id);
    setVerifyConfirmOpen(true);
  };

  const handleCloseVerifyConfirm = () => {
    setVerifyConfirmOpen(false);
    setVerifyError(null);
  };

  const handleVerifyInvoice = async () => {
    setVerifyLoading(true);
    setVerifyError(null);
    try {
      await axiosInstance.put(`/api/invoices/${selectedId}/verify`)
        .then(() => {
          enqueueSnackbar('Invoice successfully verified', {variant: 'success'});

          apiRef.current.updateRows([{skybox_id: selectedId, verified: true}]);

          setVerifyError("");
        })
        .catch(() => {
          enqueueSnackbar('An error occurred while verifying invoice', {variant: 'error'});
          setVerifyError("Verification failed");
        })
        .finally(() => {
          setVerifyConfirmOpen(false);
        });
    } catch (error) {
      setVerifyError("Verification failed");
    } finally {
      setVerifyLoading(false);
    }
  };

  const getRowClassName = useCallback(
    (params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'),
    []
  );

  const initialStateWithPagination = useMemo(
    () => ({
      pagination: {
        paginationModel: {pageSize: 100, page: 0},
        rowCount: 0,
      },
      sorting: {
        sortModel: [{field: 'date', sort: 'desc'}],
      },
      pinnedColumns: {right: ["actions"]},
    }),
    []
  );

  const dataSource: GridDataSource = useMemo(
    () => ({
      getRows: async (params) => {
        const searchParams = {
          paginationModel: encodeURIComponent(JSON.stringify(params.paginationModel)),
          filterModel: encodeURIComponent(JSON.stringify(params.filterModel)),
          sortModel: encodeURIComponent(JSON.stringify(params.sortModel)),
          year: date.year().toString(),
          month: (date.month() + 1).toString(),
          onlyUnverified: onlyUnverified.toString(),
          onlyWithPayments: onlyWithPayments.toString(),
        } as unknown as InvoicesSearchParams;

        const data = await getInvoices(searchParams);

        return {
          rows: data.invoices,
          rowCount: data.total,
        };
      },
    }),
    [date, onlyUnverified, onlyWithPayments]
  );

  return (
    <CardComponent
      icon={<ReceiptIcon/>}
      title="Skybox"
      subtitle="Invoices"
      color="#ce3197"
      defaultHeight="calc(100vh - 200px)"
      header={HeaderComponent(
        false,
        gridFilterModel,
        apiRef,
        date,
        setDate,
        onlyUnverified,
        setOnlyUnverified,
        onlyWithPayments,
        setOnlyWithPayments
      )}
    >
      <>
        <CustomDataGrid
          getRowId={(row) => row.skybox_id}
          apiRef={apiRef}
          columns={columns(handleOpenModal, handleOpenVerifyConfirm).map((col) =>
            col.field === 'actions' ? {...col, pinned: 'right'} : col
          ) as any}
          density="compact"
          getRowClassName={getRowClassName}
          loading={loading.invoices}
          paginationMode="server"
          pagination
          pageSizeOptions={[]}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          unstable_dataSource={dataSource}
          initialState={initialStateWithPagination as any}
          disableAggregation
          disableRowGrouping
          headerFilters
        />
        <PaymentsDetailModal
          open={modalOpen}
          onClose={handleCloseModal}
          payments={selectedPayments}
        />
        <VerifyConfirmModal
          open={verifyConfirmOpen}
          onClose={handleCloseVerifyConfirm}
          onConfirm={handleVerifyInvoice}
          loading={verifyLoading}
          error={verifyError}
        />
      </>
    </CardComponent>
  );
};

export default SkyboxInvoices;

import axios from "axios";
import Papa from "papaparse";

const downloadData: {
  (type: string, filenamePrefix: string, from: string, to: string): Promise<void>;
} = async (type, filenamePrefix, from, to) => {


  try {
    const data = await axios.get(`/api/${type}/all/range`, {
      params: {
        from,
        to,
        includes: false
      }
    })
      .then((res) => res.data);

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${filenamePrefix}_${from}_${to}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(`Error downloading ${type} data:`, error);
  }
};

export const downloadSales = async (from: string, to: string) => {
  await downloadData('sales', 'sales_data', from, to);
};

export const downloadInvoices = async (from: string, to: string) => {
  await downloadData('invoices', 'invoice_data', from, to);
};

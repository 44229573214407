import { useState } from "react";
import { Alert, Box, Button, CircularProgress, Divider, Modal, Typography } from "@mui/material";
import { ModalStyle } from "../../../utils/Theme";
import DownloadIcon from "@mui/icons-material/Download";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";

interface DataDownloadModalProps {
  isDs: boolean;
  isOpen: boolean;
  onClose: () => void;
  onDownload: (from: string, to: string) => Promise<void>;
}

const DataDownloadModal = ({isDs, isOpen, onClose, onDownload}: DataDownloadModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [range, setRange] = useState<DateRange<Date>>([null, null]);

  const handleDownload = async () => {
    if (!range[0] || !range[1]) {
      setError('Please select a date range');
      return;
    }
    setLoading(true);
    try {
      await onDownload(range[0].toISOString(), range[1].toISOString());
      onClose();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={ModalStyle}>
        <Box sx={{display: "flex", alignItems: "center", marginY: 2}}>
          <DownloadIcon sx={{fontSize: '35px'}}/>
          <Typography variant={'h4'}>
            Download {isDs ? 'sales' : 'invoices'} data
          </Typography>
        </Box>

        <Divider sx={{mb: 1}}/>

        <Typography variant={'body1'} sx={{mb: 2}}>
          Downloading the data will generate a CSV file with all the invoices data.
        </Typography>

        <Box sx={{display: "flex", gap: 2, justifyContent: "space-between"}}>
          <Box sx={{display: "flex", gap: 2}}>
            <DateRangePicker localeText={{start: 'From', end: 'To'}}
                             slotProps={{textField: {size: 'small', fullWidth: true}}}
                             value={range}
                             onChange={(newValue) => setRange(newValue)}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            disabled={loading}
            size="small"
          >
            {loading ? <CircularProgress size={24}/> : 'Download'}
          </Button>
        </Box>

        {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
      </Box>
    </Modal>
  );
};

export default DataDownloadModal;

// ----- Modules ----- //
import React, { useCallback, useContext, useEffect, useState } from "react";
import moment, { Moment } from "moment";

// ----- MUI ----- //
import { Backdrop, Box, Chip, CircularProgress, Divider, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { useTheme } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';

// ----- Components ----- //
import PaymentsByMarket from "./components/PaymentsByMarket";
import NoDataMessage from "../../components/cards/NoDataMessage";

// ----- Utils ----- //
import { MarketType, PaymentType } from "../../utils/Types";
import { PaymentsContext } from "../../contexts/PaymentsProvider";
import EditModal from "./components/EditModal";
import { shortcutsItems } from "./components/shortcutsItems";
import { enqueueSnackbar } from "notistack";

const Payments = () => {
  const {getPaymentsByMarkets, loading, error} = useContext(PaymentsContext);
  const theme = useTheme();

  // ----- States ----- //
  const [marketsData, setMarketsData] = useState<{
    market: MarketType,
    payments: PaymentType[],
    show: boolean
  }[]>([]);
  const [fetching, setFetching] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Moment>>([
    moment().startOf('month'),
    moment(),
  ]);
  const [selectedPayment, setSelectedPayment] = useState<PaymentType | null>(null);
  const [fetchedData, setFetchedData] = useState(false);

  // ----- Fetch Data ----- //
  const fetchData = useCallback(async () => {
      if (!dateRange[0] || !dateRange[1]) return;
      setFetching(true);
      try {
        const data = await getPaymentsByMarkets(dateRange[0].format("YYYY-MM-DD"), dateRange[1].format("YYYY-MM-DD"));
        const updatedData = data.map((market: any) => ({
          ...market,
          show: market.payments.length > 0,
        }));
        setMarketsData(updatedData);
        setFetchedData(true);
      } catch (error) {
        console.error("Error fetching payments:", error);
      } finally {
        setFetching(false);
      }
    },
    [getPaymentsByMarkets, dateRange]
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setFetchedData(false);
  }, [dateRange]);

  // ----- Handlers ----- //
  const toggleMarket = useCallback((id: number) => {
    setMarketsData((prevMarkets) =>
      prevMarkets.map((data) =>
        data.market.id === id ? {...data, show: !data.show} : data
      )
    );
  }, []);

  const updatePayment = useCallback((updatedPayment: PaymentType) => {
    enqueueSnackbar("Payment updated successfully", {variant: "success"});
    setMarketsData((prevMarkets) =>
      prevMarkets.map((data) => ({
        ...data,
        payments: data.payments.map((payment) =>
          payment.id === updatedPayment.id ? {...updatedPayment} : payment
        ),
      }))
    );
  }, []);

  return (
    <>
      <Backdrop
        sx={{color: "#fff", zIndex: theme.zIndex.drawer + 1}}
        open={loading || fetching}
      >
        <CircularProgress color="primary"/>
      </Backdrop>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.75,
          flexWrap: "wrap",
          ml: 1.5,
          mb: 2,
          justifyContent: {xs: "center", sm: "flex-start"},
        }}
      >
        <DateRangePicker
          localeText={{start: "From", end: "To"}}
          slotProps={{
            textField: {size: "small", sx: {width: 150}},
            shortcuts: {
              items: shortcutsItems,
            },
          }}
          value={dateRange}
          onChange={setDateRange}
          calendars={1}

          maxDate={moment()}
          minDate={moment('2024-01-01')}
        />

        <IconButton onClick={() => fetchData()} disabled={loading || fetchedData} color="primary">
          <SearchIcon/>
        </IconButton>

        <Divider orientation="vertical" flexItem sx={{mx: 1.5}}/>

        {loading || fetching ? (
          Array.from(new Array(12)).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={Math.floor(Math.random() * 50) + 75}
              height={32}
              sx={{borderRadius: "16px"}}
            />
          ))
        ) : (
          marketsData.map((data) => (
            <Chip
              key={data.market.id}
              label={data.market.name_alt || data.market.name}
              onClick={() => toggleMarket(data.market.id)}
              sx={{
                backgroundColor: data.market.color,
                filter: data.show ? "opacity(100%)" : "opacity(50%)",
                color: theme.palette.getContrastText(data.market.color),
              }}
            />
          ))
        )}
      </Box>

      <Grid container>
        {loading || fetching ? (
          Array.from(new Array(9)).map((_, index) => (
            <Grid item xs={12} md={6} lg={4} key={index} p={1}>
              <Skeleton variant="rounded" width="100%" height={360}/>
            </Grid>
          ))
        ) : error ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography color="error">Error: {error}</Typography>
          </Box>
        ) : marketsData.every((data) => !data.show) ? (
          <NoDataMessage message="No data available for the selected date range"/>
        ) : (
          marketsData.map((data) => (
            <MemoizedPaymentsByMarket
              key={data.market.id}
              market={data.market}
              setSelectedPayment={setSelectedPayment}
              payments={data.payments}
              show={data.show}
              dateRange={dateRange}
            />
          ))
        )}
      </Grid>

      {selectedPayment && (
        <EditModal payment={selectedPayment} onClose={() => setSelectedPayment(null)} onSave={updatePayment}/>
      )}
    </>
  );
};

const MemoizedPaymentsByMarket = React.memo(PaymentsByMarket);

export default Payments;

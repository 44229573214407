// ----- Modules ----- //
import React from "react";

// ----- MUI ----- //
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";

// ----- Icons ----- //
import ReceiptIcon from '@mui/icons-material/Receipt';
import CheckIcon from '@mui/icons-material/Check';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DownloadIcon from '@mui/icons-material/Download';

// ----- Utils ----- //
import { ValueFormatter } from "../../../utils/ValueFormatter";
import { ModalStyle } from "../../../utils/Theme";

// ----- Components ----- //
import DateSelect from "../../../components/Header/DateSelect";
import EditModal from "./EditModal";

// ----- Types ----- //
import { PaymentLineType } from "../../../utils/Types";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import DataDownloadModal from "./DataDownloadModal";
import { downloadInvoices, downloadSales } from "./DownloadData";

const PaymentsDetailModal = ({open, onClose, payments}: {
  open: boolean,
  onClose: () => void,
  payments: PaymentLineType[]
}) => (
  <Modal open={open} onClose={onClose}>
    <Box sx={ModalStyle}>
      <Box sx={{display: "flex", alignItems: "center", marginY: 2}}>
        <ReceiptIcon sx={{fontSize: '35px'}}/>
        <Typography variant={'h4'}>
          Payments
        </Typography>
      </Box>

      <Divider sx={{mb: 1}}/>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Payment ID</TableCell>
              <TableCell>Payment date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.length > 0 ? (
              payments.map(payment => (
                <TableRow key={payment.payment_id}>
                  <TableCell>{payment.payment_id}</TableCell>
                  <TableCell>{ValueFormatter.date(payment.payment_date, true)}</TableCell>
                  <TableCell>{ValueFormatter.money(payment.amount)}</TableCell>
                  <TableCell>{payment.description}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">No payments found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Modal>
);

const VerifyConfirmModal = ({open, onClose, onConfirm, loading, error}: {
  open: boolean,
  onClose: () => void,
  onConfirm: () => void,
  loading: boolean,
  error: string | null
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirm Verification</DialogTitle>
    <DialogContent>
      <DialogContentText>Are you sure you want to confirm verification of this row?</DialogContentText>
      {error && <Alert severity="error">{error}</Alert>}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} disabled={loading}>Cancel</Button>
      <Button onClick={onConfirm} disabled={loading} color="primary">
        Confirm
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: "primary",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </DialogActions>
  </Dialog>
);

const columns = (handleOpenModal: (payments: PaymentLineType[]) => void, handleOpenVerifyConfirm: (id: string) => void, ds = false) => [
  {field: 'ref', headerName: 'Ref', width: 130},
  {
    field: 'date',
    headerName: 'Invoice',
    width: 110,
    type: 'date',
    valueFormatter: (value: any) => ValueFormatter.date(value, true),
  },
  {
    field: 'event_date',
    headerName: 'Event',
    width: 110,
    type: 'date',
    valueFormatter: (value: any) => ValueFormatter.date(value, true)
  },
  {
    field: 'market.name',
    headerName: 'Market',
    type: "singleSelect",
    valueOptions: ['Vivid Seats', 'TickPick', 'Ticket Network', 'TN - Mercury', 'SeatGeek', 'Viagogo', 'GameTime', 'FanXchange', 'BILLETS.CA', 'TM Resale', 'GoTickets'],
    width: 100,
    valueGetter: (_: any, row: any) => row.market.name_alt || row.market.name,
  },
  {
    field: 'verified',
    headerName: 'Verified',
    width: 75,
    type: 'boolean',
    renderCell: (params: any) => ValueFormatter.boolean(params.row.verified)
  },
  {
    field: 'total',
    headerName: 'Invoice',
    width: 100,
    valueGetter: (_: any, rows: any) => rows.cost + rows.profit || rows.amount,
    valueFormatter: (value: any) => ValueFormatter.money(value),
    sortable: false,
  },
  {
    field: 'payments_total',
    headerName: 'Payments',
    width: 100,
    valueGetter: (_: any, rows: any) => ValueFormatter.money(rows.payment_lines.reduce((acc: number, curr: any) =>
      acc + curr.amount, 0)),
    sortable: false,
  },
  {
    field: 'payment_lines',
    headerName: 'QTY',
    width: 100,
    renderCell: (params: any) => (params.row.payment_lines || []).length,
    sortable: false,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 100,
    type: 'singleSelect',
    valueOptions: ['USD', 'CAD'],
  },
  {field: 'notes', headerName: 'Notes', width: 250},
  {
    field: 'updated_at',
    headerName: 'Updated At',
    width: 110,
    type: 'date',
    valueFormatter: (value: any) => ValueFormatter.date(value)
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 110,
    type: 'date',
    valueFormatter: (value: any) => ValueFormatter.date(value)
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 145,
    renderCell: (params: any) => (
      <>
        <IconButton
          onClick={() => handleOpenModal(params.row.payment_lines)}
          color="primary"
          disabled={!params.row.payment_lines || !params.row.payment_lines.length}
        >
          <ReceiptIcon/>
        </IconButton>

        {!ds && <EditModal invoice={params.row} lines={params.row.payment_lines}/>}

        <Tooltip title={'Verify invoice'}>
          <span>
            <IconButton
              onClick={() => handleOpenVerifyConfirm(params.row.skybox_id || params.row.invoice_id)}
              color="success"
              disabled={params.row.verified}
            >
              <CheckIcon/>
            </IconButton>
            </span>
        </Tooltip>
      </>
    ),
    sortable: false,
    filterable: false,
    pinned: 'right',
  }
];

const HeaderComponent = (
  ds: boolean,
  gridFilterModel: GridFilterModel | null,
  apiRef: any,
  date: any,
  setDate: (date: any) => void,
  onlyUnverified: boolean,
  setOnlyUnverified: (value: boolean) => void,
  onlyWithPayments: boolean,
  setOnlyWithPayments: (value: boolean) => void,
) => {
  const dsOrSb = ds ? 'sales' : 'invoices';

  const [downloading, setDownloading] = React.useState(false);

  const [dataDownloadModalOpen, setDataDownloadModalOpen] = React.useState(false);

  return (
    <>
      {(gridFilterModel?.items?.length || 0) > 0 ? (
        <>
          <Typography variant="caption" sx={{color: 'text.secondary'}}>
            Options are disabled while column filters are active
          </Typography>
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => apiRef.current.setFilterModel({items: []})}
          >
            Clear filters
          </Button>
        </>
      ) : (
        <>
          <DateSelect date={date} setDate={setDate} showMonthSelect/>

          <Tooltip title={`Download ${dsOrSb} from server, for selected month`}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => setDataDownloadModalOpen(true)}
              disabled={downloading}
            >
              <DownloadIcon/>
            </IconButton>
          </Tooltip>

          <DataDownloadModal
            isDs={ds}
            onDownload={ds ? downloadSales : downloadInvoices}
            isOpen={dataDownloadModalOpen}
            onClose={() => setDataDownloadModalOpen(false)}/>

          <Tooltip title={onlyUnverified ? `Show all ${dsOrSb}` : `Show only unverified ${dsOrSb}`}>
            <IconButton
              color={onlyUnverified ? 'error' : 'default'}
              size="small"
              sx={{opacity: onlyUnverified ? 1 : 0.5}}
              onClick={() => setOnlyUnverified(!onlyUnverified)}
            >
              <RemoveDoneIcon/>
            </IconButton>
          </Tooltip>

          <Tooltip title={onlyWithPayments ? `Show all ${dsOrSb}` : `Show only ${dsOrSb} with payments`}>
            <IconButton
              color={onlyWithPayments ? 'success' : 'default'}
              size="small"
              sx={{opacity: onlyWithPayments ? 1 : 0.5}}
              onClick={() => setOnlyWithPayments(!onlyWithPayments)}
            >
              <FormatListNumberedIcon/>
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};

export { columns, PaymentsDetailModal, VerifyConfirmModal, HeaderComponent };

// ----- Modules ----- //
import React from "react";
// ----- MUI ----- //
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
// ----- Types ----- //
import { Order } from "../types";
import Actions from "./tm/Actions";

interface OrderActionsProps {
  order: Order;
  onEdit?: (order: Order) => void;
  onDelete: (order: Order) => void;
  onPO: (order: Order) => void;
  onFetchRawData?: (order: Order) => void;
  fetchingRawData?: string;
  poing?: string;
}

const OrderActions: React.FC<OrderActionsProps> = ({
                                                     order,
                                                     onEdit,
                                                     onDelete,
                                                     onPO,
                                                     onFetchRawData,
                                                     fetchingRawData,
                                                     poing,
                                                   }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 0.25,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Tooltip title={"Soft delete this order"} arrow placement="top" disableInteractive>
        <IconButton size="small" color="error" onClick={() => onDelete(order)}>
          <DeleteIcon/>
        </IconButton>
      </Tooltip>
      {onEdit && (
        <Tooltip title={"Edit this order"} arrow placement="top" disableInteractive>
          <IconButton size="small" color="primary" onClick={() => onEdit(order)}>
            <EditIcon/>
          </IconButton>
        </Tooltip>
      )}
      {onFetchRawData && (<Actions onFetchRawData={onFetchRawData} fetchingRawData={fetchingRawData} order={order}/>)}
      <Tooltip title={"PO this order"} arrow placement="top" disableInteractive>
        <Box sx={{position: "relative"}}>
          <IconButton size="small" color="success" onClick={() => onPO(order)}
                      disabled={
                        (onFetchRawData && !order.raw_order_data)
                        || (onEdit && (!order.vividseats_id || !order.cost || !order.purchase_id))
                        || !!poing
                      }>
            <SendIcon/>
          </IconButton>
          {poing === order.id && (
            <CircularProgress
              size={24}
              sx={{
                color: "primary",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default OrderActions;

import { StandardObject } from "./standardObject";

export const adjustmentHeaderMappings: { [marketId: number]: { [key: string]: keyof StandardObject } } = {
  220566: { // Ticket Network
    'ORDER ID': 'order_id',
    'ORDER DATE': 'payment_date',
    'ADJUSTMENT AMOUNT': 'amount',
    'ADJUSTMENT CATEGORY': 'type',
    'ADJUSTMENT NOTE': 'description',
  },
  4450631: { // Tn Mercury
    'ORDER ID': 'order_id',
    'ORDER DATE': 'payment_date',
    'ADJUSTMENT AMOUNT': 'amount',
    'ADJUSTMENT CATEGORY': 'type',
    'ADJUSTMENT NOTE': 'description',
  },
};

// ----- Modules ----- //
import React, { useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
// ----- MUI ----- //
import { useGridApiRef } from "@mui/x-data-grid-premium";

// ----- Components ----- //
import { ConfirmDeleteModal, ConfirmPOModal } from "../OrderModal";
import OrderActions from "../OrderAction";
import CardComponent from "../../../../components/CardComponent";
import BulkActions from "./BulkActions";

// ----- Utils ----- //
import { CustomDataGrid } from "../../../../utils/Theme";
import { useConfiguredAxios } from "../../../../utils/AxiosInstance";
import { useSnackbar } from "notistack";
import { ValueFormatter } from "../../../../utils/ValueFormatter";
// ----- Types ----- //
import { Order } from "../../types";

interface MessiOrdersProps {
  rows: Order[];
  loading: boolean;
  fetchOrders: () => void;
}

const MessiOrders: React.FC<MessiOrdersProps> = ({rows, loading, fetchOrders}) => {
  const axiosInstance = useConfiguredAxios();
  const {enqueueSnackbar} = useSnackbar();

  const apiRef = useGridApiRef();

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [confirmPOModalOpen, setConfirmPOModalOpen] = useState<boolean>(false);
  const [fetchingRawOrderData, setFetchingRawOrderData] = useState<string | null>(null);
  const [poing, setPoing] = useState<string | null>(null);

  const handleDeleteClick = (order: Order) => {
    setSelectedOrder(order);
    setDeleteModalOpen(true);
  };

  const handleRawOrderDataClick = async (order: Order) => {
    setFetchingRawOrderData(order.id);

    await axios.post(`https://mcdavidalb.plessinc.com/purchases/parsed/orderdata/${order.id}`)
      .then(() => {
          fetchOrders();
          enqueueSnackbar("Raw order data fetched successfully.", {
            variant: "success",
            autoHideDuration: 5000,
            preventDuplicate: false
          });
        }
      ).catch((error) => {
        enqueueSnackbar("Unable to fetch the data. Our process will try and log into the account. Please try again later.", {
          variant: "error",
          autoHideDuration: 10000,
          preventDuplicate: false
        });
        console.error("Error fetching raw order data:", error);
      });

    setFetchingRawOrderData(null);
  };

  const handlePOClick = (order: Order) => {
    setSelectedOrder(order);
    setConfirmPOModalOpen(true);
  };

  const handleDeleteOrder = async (orderId: string) => {
    try {
      await axiosInstance.delete(`/api/orders/messi/${orderId}`)
        .then(() => {
            fetchOrders();
            enqueueSnackbar("Order deleted successfully.", {
              variant: "success",
              autoHideDuration: 5000,
              preventDuplicate: false
            });
          }
        ).catch((error) => {
            enqueueSnackbar("Unable to delete the order. Please try again later.", {
              variant: "error",
              autoHideDuration: 10000,
              preventDuplicate: false
            });
            console.error("Error deleting order:", error);
          }
        );
    } catch (error: any) {
      console.error("Error deleting order:", error);
    }
    fetchOrders();
  };

  const handlePOOrder = async (orderId: string) => {
    try {
      await axiosInstance.post(`https://mcdavidalb.plessinc.com/purchases/parsed/po/${orderId}`)
        .then(() => {
            fetchOrders();
            enqueueSnackbar("PO order processed successfully.", {
              variant: "success",
              autoHideDuration: 5000,
              preventDuplicate: false
            });
          }
        ).catch((error) => {
            enqueueSnackbar("Unable to process the PO order. Please try again later.", {
              variant: "error",
              autoHideDuration: 10000,
              preventDuplicate: false
            });
            console.error("Error processing PO order:", error);
          }
        );
    } catch (error: any) {
      console.error("Error processing PO order:", error);
    }
    fetchOrders();
  };

  const columns = useMemo(() => [
    {field: "order_ref", headerName: "REF", width: 125},
    {
      field: "is_ds", headerName: "DS", width: 75,
      renderCell: (params: any) => ValueFormatter.boolean(params.value),
      filterable: false,
    },
    {field: "email", headerName: "Email", width: 300},
    {field: "raw_order_data", headerName: "Raw Order Data", width: 250},
    {
      field: "_section", headerName: "Section", width: 75,
      renderCell: (params: any) =>
        JSON.parse(params.row.raw_order_data)?.items[0]?.tickets[0]?.location?.section || "",
      filterable: false,
    },
    {
      field: "_cost", headerName: "Cost", width: 75,
      renderCell: (params: any) =>
        ValueFormatter.money(JSON.parse(params.row.raw_order_data)?.payments[0]?.amount || undefined),
      filterable: false,
    },
    {
      field: "date",
      headerName: "Purchase date",
      width: 175,
      valueGetter: (value: any) => moment(value).format("MM/DD/YYYY - hh:mm A"),
      filterable: false,
    },
    {field: "event_id", headerName: "Event ID", width: 200},
    {field: "vividseats_id", headerName: "Vividseats ID", width: 125},
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      valueGetter: (params: any) => moment(params.value).format("MM/DD/YYYY"),
      filterable: false,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      valueGetter: (params: any) => moment(params.value).format("MM/DD/YYYY"),
      filterable: false,
    },
    {
      field: "actions",
      headerName: "",
      width: 125,
      renderCell: (params: any) => (
        <OrderActions
          order={params.row}
          onDelete={handleDeleteClick}
          onPO={handlePOClick}
          onFetchRawData={handleRawOrderDataClick}
          fetchingRawData={fetchingRawOrderData || ""}
          poing={poing || ""}
        />
      ),
      filterable: false,
      sortable: false,
      renderHeader: () => (
        <BulkActions selectedOrdersFct={apiRef.current.getSelectedRows}
                     setCurrentOrderROD={setFetchingRawOrderData}
                     setCurrentOrderPO={setPoing}
                     fetchOrders={fetchOrders}/>
      ),
    },
  ], [handleDeleteClick, handlePOClick, handleRawOrderDataClick, fetchingRawOrderData, poing]);

  return (
    <>
      <CardComponent title="TM Orders" color="#086ce4" defaultHeight="calc(100vh - 200px)">
        <CustomDataGrid
          apiRef={apiRef}
          // @ts-ignore
          columns={columns}
          rows={rows}
          loading={loading}
          density="compact"
          initialState={{
            pinnedColumns: {
              right: ["actions"],
            },
            columns: {
              columnVisibilityModel: {
                _section: false,
              },
            },
          }}

          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}

          headerFilters
        />
      </CardComponent>
      {selectedOrder && (
        <ConfirmDeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          orderId={selectedOrder.id}
          onDelete={handleDeleteOrder}
          market={selectedOrder.market}
        />
      )}
      {selectedOrder && (
        <ConfirmPOModal
          open={confirmPOModalOpen}
          onClose={() => setConfirmPOModalOpen(false)}
          orderId={selectedOrder.id}
          onPO={handlePOOrder}
        />
      )}
    </>
  );
};

export default MessiOrders;

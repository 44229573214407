import { IconButton, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import React from "react";

const EditPayment = ({edit}: any) => {
  return (
    <>
      <Tooltip title={'Edit payment'} disableInteractive>
        <IconButton
          size="small"
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            edit();
          }}
        >
          <EditIcon/>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default EditPayment;

import { IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";

const DownloadPayment = ({download}: any) => {

  return (
    <Tooltip title={'Download CSV'} disableInteractive>
      <IconButton
        size="small"
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          download();
        }}
      >
        <DownloadIcon/>
      </IconButton>
    </Tooltip>
  );
};

export default DownloadPayment;

// ----- Modules ----- //
import React from "react";

// ----- MUI ----- //
import { Box, Button, Divider, InputAdornment, Modal, TextField, Tooltip, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

// ----- Utils ----- //
import { ModalStyle } from "../../../utils/Theme";
import { PaymentType } from "../../../utils/Types";
import moment from "moment";
import { useConfiguredAxios } from "../../../utils/AxiosInstance";

type EditModalProps = {
  payment: PaymentType;
  onClose: () => void;
  onSave: (payment: PaymentType) => void;
};

const EditModal = ({payment, onClose, onSave}: EditModalProps) => {
  const axiosInstance = useConfiguredAxios();

  const [paymentData, setPaymentData] = React.useState<PaymentType>(payment);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    axiosInstance.put(`/api/payments/${payment.id}`, {
      payment_date: moment(paymentData.payment_date).add(1, 'day').format('YYYY-MM-DD'),
      received_date: paymentData.received_date ? moment(paymentData.received_date).add(1, 'day').format('YYYY-MM-DD') : null,
      total: paymentData.total
    }).then(() => {
      onSave(paymentData);
      onClose();
    }).catch((error) => {
      console.error("Error updating payment:", error);
    });
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
      >
        <Box sx={ModalStyle}>
          <Box sx={{display: "flex", alignItems: "center", marginY: 2}}>
            <EditIcon sx={{fontSize: '35px'}}/>
            <Typography variant={'h4'}>
              Edit payment {payment.payment_id}
            </Typography>
          </Box>

          <Divider sx={{mb: 1}}/>

          <Box sx={{display: "flex", alignItems: "center", gap: 2, my: 5}}>
            <TextField
              label="Paid date"
              variant="outlined"
              size="small"
              type="date"
              value={moment(paymentData.payment_date).format('YYYY-MM-DD')}
              onChange={(e) => setPaymentData({...paymentData, payment_date: e.target.value})}
            />
            <Tooltip
              title={"Désactivé, car le total est maintenant calculé à partir des lignes de paiement. Si besoin de changer, contacter l'administrateur pour trouver une nouvelle solution."}>
              <TextField
                disabled // TODO: Ne fonctionne plus, depuis que j'ai retiré la column total de la table payments. Voir au besoin une nouvelle solution.
                label="Total"
                variant="outlined"
                size="small"
                type="number"
                value={paymentData.total}
                onChange={(e) => setPaymentData({...paymentData, total: parseFloat(e.target.value)})}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">{paymentData.currency}</InputAdornment>
                }}
              />
            </Tooltip>
            <TextField
              label="Received Date"
              variant="outlined"
              size="small"
              type="date"
              value={paymentData.received_date ? moment(paymentData.received_date).format('YYYY-MM-DD') : ''}
              onChange={(e) => setPaymentData({...paymentData, received_date: e.target.value || null})}
              InputLabelProps={{shrink: true}}
            />
          </Box>

          <Box sx={{display: "flex", justifyContent: "flex-end", gap: 1}}>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditModal;

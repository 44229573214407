// ----- Modules ----- //
import React, { createContext, ReactNode, useCallback, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { enqueueSnackbar } from "notistack";
// ----- Types ----- //
import { Order } from "../pages/purchaseOrders/types";

interface PurchaseOrdersContextInt {
  getMessiOrders: (force?: boolean) => Promise<{ orders: Order[], total: number }>;
  getMpvOrders: (force?: boolean) => Promise<{ orders: Order[], total: number }>;
  messiLoading: boolean;
  mpvLoading: boolean;
  error: string | null;
}

interface PurchaseOrdersProviderProps {
  children: ReactNode;
}

const PurchaseOrdersContext = createContext<PurchaseOrdersContextInt>({
  getMessiOrders: async () => ({orders: [], total: 0}),
  getMpvOrders: async () => ({orders: [], total: 0}),
  messiLoading: false,
  mpvLoading: false,
  error: null,
});

const PurchaseOrdersProvider: React.FC<PurchaseOrdersProviderProps> = ({children}) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [messiLoading, setMessiLoading] = useState<boolean>(false);
  const [mpvLoading, setMpvLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [messiData, setMessiData] = useState<{ orders: Order[], total: number } | null>(null);
  const [mpvData, setMpvData] = useState<{ orders: Order[], total: number } | null>(null);

  // ----- Helper Functions ----- //
  const fetchOrders = useCallback(async (
    url: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setData: React.Dispatch<React.SetStateAction<{ orders: Order[], total: number } | null>>
  ): Promise<{ orders: Order[], total: number }> => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get(url);
      const ordersData = response.data.map((order: Order) => ({
        ...order,
        market: url.includes('messi') ? 'messi' : 'mpv',
      }));

      const formattedData = {orders: ordersData, total: ordersData.length};
      setData(formattedData);
      return formattedData;
    } catch (error: any) {
      const errorMessage = error.response?.data || 'An unexpected error occurred';
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {variant: 'error'});
      return {orders: [], total: 0};
    } finally {
      setLoading(false);
    }
  }, [axiosInstance]);

  // ----- Functions ----- //
  const getMessiOrders = useCallback(async (force: boolean = false) => {
    if (!messiData || force) {
      return fetchOrders("/api/orders/unprocessed/messi", setMessiLoading, setMessiData);
    }
    return messiData;
  }, [messiData, fetchOrders]);

  const getMpvOrders = useCallback(async (force: boolean = false) => {
    if (!mpvData || force) {
      return fetchOrders("/api/orders/unprocessed/mpv", setMpvLoading, setMpvData);
    }
    return mpvData;
  }, [mpvData, fetchOrders]);

  // ----- Render ----- //
  return (
    <PurchaseOrdersContext.Provider value={{getMessiOrders, getMpvOrders, messiLoading, mpvLoading, error}}>
      {children}
    </PurchaseOrdersContext.Provider>
  );
};

export { PurchaseOrdersContext, PurchaseOrdersProvider };

// ----- Modules ----- //
import React, { ReactNode, useCallback, useContext, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { InvoiceType, MarketInvoicesType, MarketType } from "../utils/Types";
import moment from "moment";
import { MarketsContext } from "./MarketsProvider";
import { GridGetRowsParams } from "@mui/x-data-grid-premium";

// ----- Utils ----- //
interface RefToCheckType {
  ref: string;
  invoices: InvoiceType[];
}

export interface InvoicesSearchParams extends GridGetRowsParams {
  year: string;
  month: string;
  onlyUnverified: boolean;
  onlyWithPayments: boolean;
}

interface InvoicesContextInt {
  getInvoices: (searchParams: InvoicesSearchParams) => Promise<{
    invoices: InvoiceType[],
    total: number
  }>;
  getInvoicesByMarkets: (date: moment.Moment) => Promise<MarketInvoicesType[]>;
  getRefToCheck: () => Promise<RefToCheckType[]>;
  loading: { invoices: boolean; marketInvoices: boolean; refToCheck: boolean };
  error: boolean;
}

interface InvoicesProviderProps {children: ReactNode;}

const InvoicesContext = React.createContext<InvoicesContextInt>({
  getInvoices: async () => ({invoices: [], total: 0}),
  getInvoicesByMarkets: async () => [],
  getRefToCheck: async () => [],
  loading: {invoices: true, marketInvoices: true, refToCheck: true},
  error: false,
});

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the accounts.
 * @param children - The children of the component.
 */
const InvoicesProvider = ({children}: InvoicesProviderProps) => {
  const axiosInstance = useConfiguredAxios();
  const {getMarkets} = useContext(MarketsContext);

  // ----- States ----- //
  const [loading, setLoading] = useState({invoices: false, marketInvoices: false, refToCheck: false});
  const [error, setError] = useState(false);
  const [marketInvoices, setMarketInvoices] = useState([] as MarketInvoicesType[]);
  const [refToCheck, setRefToCheck] = useState([] as RefToCheckType[]);

  // ----- Functions ----- //
  const fetchInvoices = useCallback(async (searchParams: InvoicesSearchParams): Promise<{
    invoices: InvoiceType[],
    total: number
  }> => {
    try {
      const response =
        await axiosInstance
          .get(`/api/invoices?${new URLSearchParams(searchParams as any).toString()}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [axiosInstance]);

  const fetchInvoicesByMarkets = useCallback(async (date: moment.Moment): Promise<MarketInvoicesType[]> => {
    try {
      const response = await axiosInstance.get(`/api/invoices/all?year=${date.year()}&month=${date.month() + 1}&includes=true`);
      const rawInvoices = response.data;
      const markets = await getMarkets();
      return markets.map((market: MarketType) => {
        const marketInvoices = rawInvoices.filter((invoice: InvoiceType) => invoice.market_id === market.id);
        return {market, invoices: marketInvoices};
      }).sort((a, b) => b.invoices.length - a.invoices.length);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [axiosInstance, getMarkets]);

  const fetchRefToCheck = useCallback(async (): Promise<RefToCheckType[]> => {
    try {
      const response = await axiosInstance.get('/api/invoices/ref/warning');
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [axiosInstance]);

  const getInvoices = async (searchParams: InvoicesSearchParams): Promise<{
    invoices: InvoiceType[],
    total: number
  }> => {
    setLoading(prev => ({...prev, invoices: true}));
    setError(false);

    try {
      const data = await fetchInvoices(searchParams);
      setLoading(prev => ({...prev, invoices: false}));
      return data;
    } catch (error) {
      setError(true);
      setLoading(prev => ({...prev, invoices: false}));
      return {invoices: [], total: 0};
    }
  };

  const getInvoicesByMarkets = async (date: moment.Moment): Promise<MarketInvoicesType[]> => {
    if (marketInvoices.length > 0) return marketInvoices;

    setLoading(prev => ({...prev, marketInvoices: true}));
    setError(false);

    try {
      const formattedData = await fetchInvoicesByMarkets(date);
      setMarketInvoices(formattedData);
      setLoading(prev => ({...prev, marketInvoices: false}));
      return formattedData;
    } catch (error) {
      setError(true);
      setLoading(prev => ({...prev, marketInvoices: false}));
      return [];
    }
  };

  const getRefToCheck = async (): Promise<RefToCheckType[]> => {
    if (refToCheck.length > 0) return refToCheck;

    setLoading(prev => ({...prev, refToCheck: true}));
    setError(false);

    try {
      const refData = await fetchRefToCheck();
      setRefToCheck(refData);
      setLoading(prev => ({...prev, refToCheck: false}));
      return refData;
    } catch (error) {
      setError(true);
      setLoading(prev => ({...prev, refToCheck: false}));
      return [];
    }
  };

  // ----- Render ----- //
  return (
    <InvoicesContext.Provider value={{getInvoices, getInvoicesByMarkets, getRefToCheck, loading, error}}>
      {children}
    </InvoicesContext.Provider>
  );
};

export { InvoicesContext, InvoicesProvider };

// ----- Modules ----- //
import React, { useEffect, useMemo, useRef } from "react";
import axios, { CancelTokenSource } from "axios";
import debounce from "lodash/debounce";
// ----- Components ----- //
import CardComponent from "../../components/CardComponent";
import EmailModal from "./components/EmailModal";
import { getColumns } from "./components/EmailColumns";
// ----- MUI ----- //
import { Box, IconButton, TextField } from "@mui/material";
// ----- Utils ----- //
import { CustomDataGrid } from "../../utils/Theme";
import { useConfiguredAxios } from "../../utils/AxiosInstance";
// ----- Icons ----- //
import EmailIcon from '@mui/icons-material/Email';
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from '@mui/icons-material/Refresh';

interface Emails {
  id: number;
  s3_id: string;
  title: string;
  sender: string;
  recipient: string;
  search_text?: string;
  updated_at: Date;
  created_at: Date;
}

const Emails = () => {
  const axiosInstance = useConfiguredAxios();
  let cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [textSearch, setTextSearch] = React.useState<string>('');
  const [emails, setEmails] = React.useState<Emails[]>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [selectedEmailContent, setSelectedEmailContent] = React.useState<{
    subject: string;
    sender: string;
    recipient: string;
    datetime: string;
    html: string;
    text: string;
    attachments: any[];
  }>({
    subject: '',
    sender: '',
    recipient: '',
    datetime: '',
    html: '',
    text: '',
    attachments: [],
  });

  const header = (
    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
      <SearchIcon sx={{color: 'gray', mr: 1}}/>
      <TextField
        variant="standard"
        size="small"
        sx={{width: 200}}
        value={textSearch}
        onChange={(e) => setTextSearch(e.target.value)}
        placeholder="Search"
      />
      <IconButton
        aria-label="refresh"
        onClick={() => fetchEmails()}
        color="primary"
        size="small"
        sx={{p: 0.25}}
      >
        <RefreshIcon/>
      </IconButton>
    </Box>
  );

  const handleViewEmail = async (s3Id: string) => {
    try {
      const response = await axiosInstance.get(`/api/emails/${s3Id}`);
      setSelectedEmailContent({
        subject: response.data.subject,
        sender: response.data.sender,
        recipient: response.data.recipient,
        datetime: response.data.created_at,
        html: response.data.html,
        text: response.data.text,
        attachments: response.data.attachments,
      });
      setModalOpen(true);
    } catch (error) {
      console.error('Error fetching email content:', error);
    }
  };

  // Function to fetch emails based on the search text
  const fetchEmails = async () => {
    setLoading(true);

    cancelTokenSource.current = axios.CancelToken.source();

    try {
      const response = await axiosInstance.get('/api/emails', {
        params: {
          search: textSearch,
        },
        cancelToken: cancelTokenSource.current.token,
      });

      if (response.data) setEmails(response.data);
      else setEmails([]);
    } catch (error) {
      console.error('Error fetching emails:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedGetEmails = useMemo(
    () => debounce(fetchEmails, 500),
    [textSearch],
  );

  useEffect(() => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Operation canceled due to new request.");
    }

    if (textSearch === '') return;

    debouncedGetEmails();

    return () => {
      debouncedGetEmails.cancel();
    };
  }, [textSearch]);

  return (
    <>
      <CardComponent
        title="Emails"
        color="#4A90E2"
        icon={<EmailIcon/>}
        subtitle={`Search through emails from the last 5 days.`}
        defaultHeight="calc(100vh - 200px)"
        hideMaximize
        header={header}
      >
        <CustomDataGrid columns={getColumns(handleViewEmail)} rows={emails} loading={loading} density="compact"/>
      </CardComponent>

      <EmailModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        subject={selectedEmailContent.subject}
        sender={selectedEmailContent.sender}
        recipient={selectedEmailContent.recipient}
        datetime={selectedEmailContent.datetime}
        htmlContent={selectedEmailContent.html}
        textContent={selectedEmailContent.text}
        attachments={selectedEmailContent.attachments}
      />
    </>
  );
};

export default Emails;

import { askDate, ExtractedInfo } from "../utils";

export const extractTnMercuryInfo = async (fileName: string): Promise<ExtractedInfo> => {
  let paymentDate: string | null = null;
  let paymentId: string | null = null;
  let account: 'LONG' | 'DS' = 'LONG';

  const idPattern = /Remittance_(\d+)_/;
  const match = fileName.match(idPattern);
  if (match) paymentId = match[1];

  const adjustmentsPattern = /Adjustments/;
  const isAdjustments = adjustmentsPattern.test(fileName);


  if(!isAdjustments) {
    const selectedDate = await askDate();
    if (selectedDate) {
      const date = new Date(selectedDate.setHours(8, 0, 0));
      paymentDate = date.toISOString().slice(0, 19);
    }
  }

  return {paymentDate, paymentId, account, isAdjustments};
};
